<template>

    <v-dialog v-model="dialogoImportacaoDePeca" max-width="90%" scrollable @keydown.esc="sair()">
    
      <v-card>
        <v-card-title class="subtitle-1 cyan white--text">
          Importação de Peças
        </v-card-title>
        <v-card-text class="mt-4"> 
          

        <v-container class="fill-height fill-width pt-10">
          <v-alert
            color="primary lighten-1 fill-width pl-8"
            width="10000"
            dark
            icon="info"
            border="left"
            prominent
          >
            <v-col class="pl-8">
              <v-row class="pb-5">
                <h1 class="text-h3">Atenção!</h1>
              </v-row>
              <v-row>
                <v-icon>check</v-icon>A base utilizada para importação de peça
                deve ser identica a base extraida acima (Base de importação de
                peça)
                <b class="pl-2">Não exclua, adicione ou mova colunas.</b>
              </v-row>
              <v-row>
                <v-icon>check</v-icon>Linhas sem valores ou fora do padrão serão
                desconsideradas
              </v-row>
              <v-row>
                <v-icon>check</v-icon>Serão sinalizadas como erro linhas
                duplicadas
              </v-row>
              <v-row>
                <v-icon>check</v-icon>As imagens devem estar em formato png ou
                jpeg
              </v-row>
              <v-row>
                <v-icon>check</v-icon>Nome da imagem deve ser identico ao nome
                da peça
                <i class="pl-2"
                  >ex: material peça 1 deve ter a imagem peça1.png</i
                >
              </v-row>
            </v-col>
          </v-alert>

          <v-col>
            <v-row>
              <v-btn @click="DownloadBase" color="primary">
                <v-icon left>file_download</v-icon>
                <span>Download base</span>
              </v-btn>
            </v-row>
            <v-row class="mt-5">
              <v-file-input
                prepend-icon="upload_file"
                small-chips
                clearable
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                chips
                label="Base preenchida"
                v-model="xls"
              ></v-file-input>
            </v-row>
            <v-row>
              <v-file-input
                prepend-icon="mdi-file-image"
                accept="image/*"
                multiple
                counter
                clearable
                small-chips
                label="Imagens 3D"
                v-model="imagem3D"
              ></v-file-input>
            </v-row>
            <v-row>
              <v-file-input
                prepend-icon="mdi-file-image"
                accept="image/*"
                multiple
                counter
                clearable
                small-chips
                label="Imagens de Positivação"
                v-model="imagemPositivacao"
              ></v-file-input>
            </v-row>

     
          </v-col>
          
             
              <v-snackbar v-model="snackbar">
                Ainda existem ações a serem tomadas
                <template v-slot:action="{ attrs }">
                  <v-btn color="red" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
                </template>
              </v-snackbar>

      


        </v-container>



  


        </v-card-text>
        <v-divider></v-divider>        
    
     <v-spacer></v-spacer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error"  @click="sair" > <v-icon>mdi-close</v-icon>Cancelar</v-btn>
          <v-btn class="cyan" @click="next"><v-icon>mdi-chevron-right</v-icon>Continuar</v-btn>
        </v-card-actions>


    </v-card>
       
        
    
    </v-dialog>

</template>

<script>
import XLSX from "xlsx";

export default {

  props: {
    dialogoImportacaoDePeca: {
      type: Boolean,
    },
  },
  data: () => ({
     snackbar: false,
    snackbarText: "",
    xls: null,
    imagem3D: [],
    imagemPositivacao: [],
    step: '1'
    
  }),
  computed: {},
  methods: {
    sair() {      
      this.$emit("sairmodal",false );    
    },
    algo(files) {
      console.log(files);
    },
   DownloadBase(){
      const data = [{
        'Nome da Peça Admin':'',
        'Nome da Peça App':'',
        'Observação':''
      }]
      const ws = XLSX.utils.json_to_sheet(data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Lista de Peças para Importação");
        XLSX.writeFile(wb, `base_modelo_importacao_pecas.xlsx`);
    },
   next() {

     if(this.xls){
      
            try {
              this.$store.commit('importacaoPecas/selectFiles', {xls:this.xls,imagem3D:this.imagem3D,imagemPositivacao:this.imagemPositivacao})
              this.$store.commit("dialogostep/MudarState", {step:2})
          } catch (error) {
            this.snackbar = true
          }
     }else{
       this.snackbar = true
     }
 
    }
  },
 
};
</script>